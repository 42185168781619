import { QueryClient } from '@tanstack/react-query';

import { staleTime1Day, staleTime1Min } from './constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: staleTime1Day, gcTime: staleTime1Min },
  },
});

export default queryClient;
