import { useToast } from '@chakra-ui/toast';
import { initializePaddle } from '@paddle/paddle-js';
import { LineItem } from '@paddle/paddle-js/types/price-preview/price-preview';
import navRoutes from 'navigation/Routes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMarkDiscountCodeRedeemed, useCreateDraftTransaction } from 'react-query/hooks/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authActions } from 'redux/actions';
import { GlobalState, UserDetail } from 'types';
import { getParamFromUrl } from 'utils';

const PaddleToken = process.env.REACT_APP_PADDLE_AUTH_TOKEN || '';
const PaddleSandboxMode = process.env.REACT_APP_PADDLE_SANDBOX_MODE === 'true';
const PaddleMonthlyPriceId = process.env.REACT_APP_PADDLE_MONTHLY_PRICE_ID || '';
const PaddleYearlyPriceId = process.env.REACT_APP_PADDLE_YEARLY_PRICE_ID || '';
const PaddleMonthlyPriceIdNoTrial = process.env.REACT_APP_PADDLE_MONTHLY_PRICE_ID_NO_TRIAL || '';
const PaddleYearlyPriceIdNoTrial = process.env.REACT_APP_PADDLE_YEARLY_PRICE_ID_NO_TRIAL || '';

async function getGeoLocation() {
  try {
    let response = await fetch(
      `https://pro.ip-api.com/json/?key=${process.env.REACT_APP_IP_API_KEY}`,
    );
    let responseJson = await response.json();
    return responseJson as { countryCode: string };
  } catch (error) {
    console.error(error);
  }
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const usePortalSession = () => {
  const [portalUrl, setPortalUrl] = useState<string | null>(null);
  const [isLoadingPortal, setIsLoadingPortal] = useState(true);
  const dispatch = useDispatch();

  const user = useSelector((state: GlobalState) => state.user);
  const { isLoadingUser } = useSelector((state: GlobalState) => state.ui);

  const toast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  const getPortalUrl = async () => {
    const checkoutRes = await dispatch(authActions.createPortalSession());

    // @ts-ignore
    return checkoutRes?.payload?.data?.urls?.general?.overview || null;
  };

  useEffect(() => {
    const initPortalSession = async () => {
      if (isLoadingUser) return;
      if (user?.subscriptionStore !== 'paddle') {
        setIsLoadingPortal(false);
        return;
      }

      const checkoutUrl = await getPortalUrl();
      if (!checkoutUrl) {
        toast({
          title: 'Error',
          description: 'An error occurred. Please refresh the page and try again.',
          status: 'error',
          duration: 2000,
          isClosable: false,
        });
      }
      setIsLoadingPortal(false);
      setPortalUrl(checkoutUrl);
    };

    initPortalSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.subscriptionStore, isLoadingUser]);

  return { isLoadingPortal, portalUrl };
};

type OnCheckoutSuccess = (
  data: {
    customerId: string | undefined;
    discountCode: string | null | undefined;
    transactionId: string | undefined;
  } | null,
  count?: number,
) => void;

export const initCheckout = async ({
  discountCode,
  email,
  onCheckoutClosed,
  onCheckoutSuccess,
  priceId,
  userUid,
  transactionId,
}: {
  discountCode?: string | null;
  email: string;
  onCheckoutClosed: () => void;
  onCheckoutSuccess: OnCheckoutSuccess;
  priceId?: string;
  userUid: string;
  transactionId?: string;
}) => {
  const Paddle = await initializePaddle({
    token: PaddleToken,
    eventCallback: e => {
      if (e.name === 'checkout.completed') {
        onCheckoutSuccess({
          customerId: e.data?.customer.id,
          discountCode: e.data?.discount?.code,
          transactionId,
        });
      }
      if (e.name === 'checkout.closed') {
        onCheckoutClosed();
      }
    },
  });

  if (PaddleSandboxMode) {
    Paddle?.Environment.set('sandbox');
  }

  const baseData = priceId
    ? { priceId, items: [{ priceId, quantity: 1 }] }
    : transactionId
    ? { transactionId }
    : null;

  if (!baseData) return;

  const checkoutData = {
    ...baseData,
    customer: { email },
    customData: { user_uid: userUid },
    discountCode,
    settings: {
      displayMode: 'overlay',
      theme: 'light',
      locale: 'en',
    },
  } as const;

  Paddle?.Checkout.open(checkoutData);
};

const useCheckout = ({
  history,
  eventId,
  loadingState: { setIsLoading },
  shouldLoadUser = true,
}: {
  history: any;
  eventId: 'web_app_signup' | 'web_app_login';
  loadingState: {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
  };
  shouldLoadUser?: boolean;
}) => {
  const [postCheckoutInProgress, setPostCheckoutInProgress] = useState(false);
  const checkoutCompletedRef = useRef(false);

  const authToken = useSelector((state: GlobalState) => state.auth.authToken);

  const user = useSelector((state: GlobalState) => state.user);
  const { email: userEmail, uid: userUid, isEligibleForFreeTrial } = user;

  const dispatch = useDispatch();
  const location = useLocation();

  let priceIdParam = getParamFromUrl(location, 'price_id');
  const updateCardParam = getParamFromUrl(location, 'update_card_details');

  // Map old Stripe price ID to new Paddle price ID
  if (priceIdParam === 'price_1Mo1A5AiIK7kWfaqkif6LuSt') {
    priceIdParam = PaddleMonthlyPriceId;
  }

  const discountCode = getParamFromUrl(location, 'coupon_code');

  const { markDiscountCodeRedeemed } = useMarkDiscountCodeRedeemed({});
  const { createDraftTransaction } = useCreateDraftTransaction({});

  const toast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  const loadUserData = async (updateLoadingState = true) => {
    if (updateLoadingState) setIsLoading(true);
    const res = await dispatch(authActions.retrieveUser());
    if (updateLoadingState) setIsLoading(false);

    // @ts-ignore
    if (!res?.payload) {
      toast({
        title: 'Error',
        description: 'An error occurred. Please refresh the page and try again.',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
      return;
    }

    // @ts-ignore
    const user = res.payload;
    // @ts-ignore
    const uid: string = user.uid;
    // @ts-ignore
    const email: string = user.email;

    if (uid) {
      _cio.identify({
        // Required attributes
        id: uid,
        email: email,
      });
      _cio.track(eventId);
    }

    return user as UserDetail;
  };

  const onCheckoutClosed = () => {
    if (checkoutCompletedRef.current) return;
    history.push(navRoutes.public.subscribe.path());
  };

  // Recursively call this function to get the user data
  // check if user.subscriptionStore is set to 'paddle'
  // -- if set, redirect to manage subscription page
  // -- if not set, wait 1s, then call this function again
  // repeat until count > 10
  const onCheckoutSuccess: OnCheckoutSuccess = async (data, count = 0) => {
    if (!data) {
      // Add error handling
      return;
    }

    if (count === 0 && data.discountCode) {
      markDiscountCodeRedeemed(data.discountCode);
    }

    setPostCheckoutInProgress(true);
    setIsLoading(false);

    if (!checkoutCompletedRef.current) {
      checkoutCompletedRef.current = true;
    }

    setIsLoading(true);

    if (count > 10) {
      toast({
        title: 'Error',
        description: 'An error occurred. Please refresh the page and try again.',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
      setPostCheckoutInProgress(false);
      return;
    }

    const user = await loadUserData(false);

    if (user?.subscriptionStore === 'paddle') {
      history.push(navRoutes.public.manageSubscription.path());
      setPostCheckoutInProgress(false);
      return;
    }

    await sleep(1000).then(() => onCheckoutSuccess(data, count + 1));
  };

  const initCheckoutFunc = useCallback(
    async (priceIdArg?: string) => {
      let transactionId: string | undefined;
      if (!authToken) {
        // TODO : Handle error
        return;
      }

      let priceId = priceIdParam || priceIdArg;
      if (!priceId && !updateCardParam) {
        // TODO : Handle error
        return;
      }

      let email = userEmail;

      if (shouldLoadUser) {
        const user = await loadUserData();
        if (user) email = user.email;
      }

      if (updateCardParam) {
        const data = await createDraftTransaction();
        transactionId = data?.data.transaction_id;
      }

      if (!priceId && !transactionId) {
        // TODO : Handle error
        return;
      }

      if (!isEligibleForFreeTrial) {
        if (priceId === PaddleMonthlyPriceId) {
          priceId = PaddleMonthlyPriceIdNoTrial;
        }
        if (priceId === PaddleYearlyPriceId) {
          priceId = PaddleYearlyPriceIdNoTrial;
        }
      }

      if (!email) {
        toast({
          title: 'Error',
          description: 'An error occurred. Please refresh the page and try again.',
          status: 'error',
          duration: 2000,
          isClosable: false,
        });
        return;
      }

      initCheckout({
        email,
        priceId,
        onCheckoutSuccess,
        onCheckoutClosed,
        discountCode,
        userUid,
        transactionId,
      });
    },

    // eslint-disable-next-line
    [
      authToken,
      dispatch,
      eventId,
      priceIdParam,
      toast,
      setIsLoading,
      shouldLoadUser,
      userEmail,
      discountCode,
      isEligibleForFreeTrial,
    ],
  );

  return {
    initCheckout: initCheckoutFunc,
    priceId: priceIdParam,
    postCheckoutInProgress,
    updateCardParam,
  };
};

export const usePrices = ({
  loadingState: { setIsLoading },
}: {
  loadingState: { isLoading: boolean; setIsLoading: (isLoading: boolean) => void };
}) => {
  const [prices, setPrices] = useState<LineItem[] | undefined>();

  useEffect(() => {
    const loadData = async () => {
      const Paddle = await initializePaddle({
        token: PaddleToken,
        pwCustomer: {},
      });

      if (PaddleSandboxMode) {
        Paddle?.Environment.set('sandbox');
      }

      const geo = await getGeoLocation();

      var request = {
        items: [
          { quantity: 1, priceId: PaddleYearlyPriceId },
          { quantity: 1, priceId: PaddleMonthlyPriceId },
        ],
        address: { countryCode: geo?.countryCode || 'GB' },
      };

      const res = await Paddle?.PricePreview(request);
      setPrices(res?.data.details.lineItems);

      setIsLoading(false);
    };

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { prices };
};

export default useCheckout;
