import { Spinner, Divider, Flex, Heading, Paragraph, Text, Link } from '@workshop/ui';
import { useAuthMethods } from 'hooks/auth';
import { usePortalSession } from 'hooks/checkout';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { GlobalState } from 'types';
import navRoutes from 'navigation/Routes';

const screenshot1 = require('../../../assets/app_screenshots/screenshot_1.png');
const screenshot2 = require('../../../assets/app_screenshots/screenshot_2.png');
const screenshot3 = require('../../../assets/app_screenshots/screenshot_3.png');
const screenshot4 = require('../../../assets/app_screenshots/screenshot_4.png');
const screenshot5 = require('../../../assets/app_screenshots/screenshot_5.png');
const screenshot6 = require('../../../assets/app_screenshots/screenshot_6.png');

const getSubscriptionsStoreLabel = (
  store: 'none' | 'app_store' | 'google_play' | 'stripe' | 'paddle' | null | undefined,
) => {
  if (store === 'app_store') return 'the App Store (iOS)';
  if (store === 'google_play') return 'the Google Play Store (Android)';
  if (store === 'stripe') return 'Stripe';
  if (store === 'paddle') return 'Paddle';
  return '';
};

const aspectRatio = 1398 / 645;

const defaultScreenshotStyle = {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '75vh',
  width: '100%',
} as const;

const ManageSubScreen: React.FC<{ history: any }> = ({ history }) => {
  const user = useSelector((state: GlobalState) => state.user);
  const { isLoadingPortal, portalUrl } = usePortalSession();

  const { signOut } = useAuthMethods({});

  const { isLoadingUser } = useSelector((state: GlobalState) => state.ui);

  useEffect(() => {
    if (isLoadingUser) return;
    if (!user?.subscriptionStore) {
      history.push(navRoutes.public.subscribe.path());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser, user?.subscriptionStore]);

  const subStoreLabel = getSubscriptionsStoreLabel(user?.subscriptionStore);

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Choose your plan</title>
      </Helmet>
      <Flex
        flex="0 0 40%"
        backgroundColor="primary"
        flexDirection="column"
        alignItems="center"
        minHeight={['0', '0', '80vh']}
        pt={['10', '10', '20']}
        pb="10"
        px="20"
        minH="100vh"
      >
        <Flex display={['none', 'none', 'flex']}>
          <Flex
            borderRadius="lg"
            overflow="hidden"
            width="100%"
            height="100%"
            borderWidth="4px"
            borderColor="#00000036"
            style={{
              boxShadow: '6px 10px 20px -6px black',
            }}
          >
            <Carousel
              autoPlay
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              showIndicators={false}
              renderArrowNext={() => null}
              renderArrowPrev={() => null}
              // width="30vw"
              // height: '75vh',
              width={`${75 / aspectRatio}vh`}
              swipeable
            >
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot1})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot2})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot3})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot4})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot5})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot6})` }} />
            </Carousel>
          </Flex>
        </Flex>
      </Flex>
      {isLoadingUser || isLoadingPortal ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '0']}
          maxW={750}
          height="100%"
        >
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent={['flex-start', 'flex-start', 'flex-start']}
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '0']}
          maxW={750}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading fontSize={['2xl', '2xl', '4xl']} mb="4">
            Your Sidekick Subscription
          </Heading>
          {subStoreLabel ? (
            <Paragraph>You are currently subscribed via {subStoreLabel}.</Paragraph>
          ) : (
            <Paragraph>
              Please get in touch with our customer support team for assistance with your
              subscription.
            </Paragraph>
          )}
          {user?.subscriptionStore === 'app_store' || user?.subscriptionStore === 'google_play' ? (
            <Text fontSize="sm">Please use your device to manage your subscription.</Text>
          ) : null}

          {user?.subscriptionStore === 'stripe' ? (
            <Text fontSize="sm">
              Please get in touch with our customer support team for assistance with your
              subscription.
            </Text>
          ) : null}
          {portalUrl ? (
            <Text fontSize="sm">
              <Text fontSize="sm" as="span" fontWeight="bold" color="secondary" cursor="pointer">
                <Link href={portalUrl}>Manage your subscription.</Link>
              </Text>
            </Text>
          ) : null}
          {user.email && (
            <>
              <Divider my="4" />
              <Text fontSize="sm">You are currently signed in as: {user.email}</Text>
              <Text
                mt="2"
                fontSize="sm"
                onClick={async () => {
                  await signOut();
                  history.push(navRoutes.public.subscribe.path());
                }}
                as="span"
                fontWeight="bold"
                color="secondary"
                cursor="pointer"
              >
                Sign out.
              </Text>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default ManageSubScreen;
