import { User } from '@firebase/auth';
import moment from 'moment';
import sleep from './sleep';

/**
 * AuthTokenManager
 *
 * The purpose of this class is to manage the refreshing of the auth token.
 * We want to keep track of any refreshes currently in progress, and ensure
 * that we only have one refresh in progress at a time.
 *
 * We also want to prevent frequent refreshes, so we should only refresh the
 * token if it's close to expiring (i.e at least 5mins after the last refresh).
 */
class AuthTokenManager {
  private static instance: AuthTokenManager;

  private isRefreshing = false;

  private lastRefreshTime: moment.Moment | null = null;

  private token = '';

  private constructor() {
    // Private constructor to prevent instantiation
  }

  public static getInstance(): AuthTokenManager {
    if (!AuthTokenManager.instance) {
      AuthTokenManager.instance = new AuthTokenManager();
    }

    return AuthTokenManager.instance;
  }

  public clearToken() {
    this.token = '';
  }

  public getToken() {
    return this.token;
  }

  /**
   * Refreshes the auth token if it's close to expiring.
   * @param user
   * @param dispatch
   */
  public async refreshAuthToken(
    user: User,
    forceRefresh: boolean = false,
  ): Promise<string> {
    if (this.isRefreshing && !forceRefresh) {
      if (!this.token) {
        await sleep(100);
        return this.refreshAuthToken(user, forceRefresh);
      }

      return this.token;
    }

    if (this.lastRefreshTime?.isAfter(moment().subtract(5, 'minutes')) && !forceRefresh) {
      return this.token;
    }

    this.isRefreshing = true;
    this.lastRefreshTime = moment();

    const response = await user.getIdTokenResult(true);

    this.isRefreshing = false;

    const { token } = response;
    this.token = response.token;

    return token;
  }
}

export const authTokenManager = AuthTokenManager.getInstance();

export default authTokenManager;
