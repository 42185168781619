const AuthAT = {
  AUTH_COMPLETED: 'AUTH_COMPLETED',
  CLEAR_AUTH_ERROR: 'CLEAR_AUTH_ERROR',

  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_OUT: 'SIGN_OUT',

  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',

  CREATE_PORTAL_SESSION_REQUEST: 'CREATE_PORTAL_SESSION_REQUEST',
  CREATE_PORTAL_SESSION_SUCCESS: 'CREATE_PORTAL_SESSION_SUCCESS',
  CREATE_PORTAL_SESSION_FAILURE: 'CREATE_PORTAL_SESSION_FAILURE',

  CREATE_CHECKOUT_SESSION_REQUEST: 'CREATE_CHECKOUT_SESSION_REQUEST',
  CREATE_CHECKOUT_SESSION_SUCCESS: 'CREATE_CHECKOUT_SESSION_SUCCESS',
  CREATE_CHECKOUT_SESSION_FAILURE: 'CREATE_CHECKOUT_SESSION_FAILURE',

  RETRIEVE_USER_REQUEST: 'RETRIEVE_USER_REQUEST',
  RETRIEVE_USER_SUCCESS: 'RETRIEVE_USER_SUCCESS',
  RETRIEVE_USER_FAILURE: 'RETRIEVE_USER_FAILURE',
} as const;

export default AuthAT;
