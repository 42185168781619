import { Flex, Heading, Spinner } from '@workshop/ui';
import { useAuthMethods } from 'hooks/auth';
import useCheckout from 'hooks/checkout';
import navRoutes from 'navigation/Routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { LoginForm } from 'screens/Login/LoginForm';

const logoLarge = require('../../../assets/logo_large.png');
const aubergineImg = require('../../../assets/courgette@3x.png');

const LoginScreen: React.FC<{ history: any }> = ({ history }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { initCheckout, postCheckoutInProgress, priceId, updateCardParam } = useCheckout({
    loadingState: { isLoading, setIsLoading },
    history,
    eventId: 'web_app_login',
  });

  const { onSubmit } = useAuthMethods({
    authCallback:
      priceId || updateCardParam
        ? initCheckout
        : async () => {
            history.push(navRoutes.public.manageSubscription.path());
          },
    loadingState: { isLoading, setIsLoading },
  });

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Sign in</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoLarge}
        width="140px"
        height="30px"
        position="absolute"
        left={4}
        bottom={4}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom"
      />
      {postCheckoutInProgress && (
        <Flex
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1000}
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1000}
            bgColor="black"
            opacity={0.2}
          />
          <Spinner />
        </Flex>
      )}
      <Flex
        alignItems="center"
        backgroundColor="primary"
        flex="0 0 40%"
        flexDirection="column"
        height={['0', '0', '100vh']}
        justifyContent="flex-start"
        pb={['5', '5', '0']}
        pt={['5', '5', '40']}
        px={['0', '0', '0']}
      >
        <Heading display={['flex', 'flex', 'none']} fontSize={['2xl', '2xl', '4xl']}>
          Sign in to get started
        </Heading>
        <Flex
          display={['none', 'none', 'block']}
          width="100%"
          height="20vw"
          backgroundImage={aubergineImg}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        minHeight="100vh"
        backgroundColor="white"
        flex={1}
        px={['10', '10', '20']}
        pt={['10', '10', '0']}
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        <Heading display={['none', 'none', 'flex']} fontSize={['2xl', '2xl', '4xl']} mb="8">
          Sign in to get started
        </Heading>
        <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
      </Flex>
    </Flex>
  );
};

export default LoginScreen;
