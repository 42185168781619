import { useToast } from '@chakra-ui/toast';
import {
  signOut as firebaseSignOut,
  signInWithEmailAndPassword,
  UserCredential,
} from '@firebase/auth';
import { FirebaseError } from '@firebase/util';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/actions';
import { GlobalState, LoginData, SignInSuccess, SignupData, UserInfo } from 'types';
import { auth } from 'utils';

const authCompletedAction = () => ({
  type: 'AUTH_COMPLETED',
});

const signInSuccessAction = (userInfo: UserInfo): SignInSuccess => ({
  type: 'SIGN_IN_SUCCESS',
  payload: { ...userInfo },
});

export const signOutAction = () => ({
  type: 'SIGN_OUT',
});

type AuthParams = {
  authCallback?: () => void;
  loadingState?: {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
  };
};

export const useAuthenticateUser = () => {
  const [initAuthCompleted, setInitAuthCompleted] = useState(false);

  const dispatch = useDispatch();

  const authToken = useSelector((state: GlobalState) => state.auth.authToken);

  // When the application is first loaded, attempt to refresh the JWT token, if one is present
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdTokenResult();

        // Send the token to the redux store
        dispatch(
          signInSuccessAction({
            token: token.token,
            expirationTime: token.expirationTime,
            displayName: user.displayName,
          }),
        );
      }

      setInitAuthCompleted(true);
    });

    return unsubscribe();
  }, [setInitAuthCompleted, dispatch]);

  useEffect(() => {
    if (!authToken && !initAuthCompleted) return;

    if (authToken) {
      dispatch(authActions.retrieveUser());
    } else {
      dispatch(authCompletedAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAuthCompleted, authToken]);
};

export const useAuthMethods = ({ authCallback, loadingState }: AuthParams) => {
  const setIsLoading = loadingState?.setIsLoading || (() => {});

  const [authSuccessful, setAuthSuccessful] = useState(false);

  const dispatch = useDispatch();

  const toast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  useEffect(() => {
    if (!authSuccessful) return;

    if (authCallback) {
      authCallback();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSuccessful]);

  const signOut = async () => {
    await firebaseSignOut(auth);
    dispatch(signOutAction());
  };

  const signUp = async ({ email, name, password }: SignupData) => {
    // Call our own API to create the user with name+email+password
    const signupRes = await dispatch(
      authActions.signupRequest({
        email,
        name,
        password,
      }),
    );

    // @ts-ignore
    if (signupRes?.error) {
      toast({
        title: 'Sign-up failed. Please try again later.',
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
      setIsLoading(false);
      return;
    }
  };

  const onSubmit = async (data: SignupData | LoginData) => {
    setIsLoading(true);

    if ('name' in data) {
      await signUp(data);
    }

    // Attempt to sign in via Firebase after successful signup
    // (i.e, authenticate and retrieve the auth token)
    let userCredential: UserCredential | undefined;
    try {
      userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (err) {
      const error = err as FirebaseError;

      let errorMessage = '';
      switch (error.code) {
        case 'auth/wrong-password':
          errorMessage = 'Invalid username/password combination. Please try again.';
          break;

        case 'auth/user-not-found':
          errorMessage =
            'We could not find a user with this email address in our system. Please try again.';
          break;

        default:
          errorMessage = 'Login failed. Please try again.';
          break;
      }

      toast({
        title: errorMessage,
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
      setIsLoading(false);
      return;
    }

    if (!userCredential) {
      toast({
        title: 'Failed to retrieve credentials. Please reresh the page and try again.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const token = await userCredential.user.getIdTokenResult();
    setIsLoading(false);

    // Send the token to the redux store
    dispatch(
      signInSuccessAction({
        token: token.token,
        expirationTime: token.expirationTime,
        displayName: userCredential.user.displayName,
      }),
    );

    setAuthSuccessful(true);
  };

  return { onSubmit, signOut };
};

const hooks = { signInSuccessAction, useAuthenticateUser, useAuthMethods };

export default hooks;
