import { Flex, Heading, Paragraph, Spinner, Text } from '@workshop/ui';
import React from 'react';

const PriceButton: React.FC<{
  label: string;
  billing: 'monthly' | 'yearly';
  priceLabel: string;
  isLoading: boolean;
  isSelected: boolean;
  onClick: () => void;
}> = ({ label, billing, priceLabel, isLoading, isSelected, onClick }) => {
  return (
    <Flex
      p="4"
      flex={1}
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      borderWidth={4}
      borderRadius="lg"
      minH={[100, 150, 220]}
      cursor="pointer"
      borderColor={isSelected ? 'primary' : 'transparent'}
      boxShadow={isSelected ? 'lg' : 'inset 0px 0px 0px 2px #e6e6e6'}
      onClick={onClick}
      position="relative"
      width="100%"
      maxW={['none', 'none', 250]}
    >
      {isLoading && (
        <>
          <Flex />
          <Spinner />
          <Flex />
        </>
      )}
      {!isLoading && (
        <>
          {billing === 'yearly' && (
            <Flex
              position="absolute"
              right={4}
              top={-30}
              backgroundColor="primary"
              h={30}
              px="2"
              borderTopRadius="md"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontFamily="lemonado" textTransform="uppercase">
                best value
              </Text>
            </Flex>
          )}
          <Heading fontFamily="lemonado" textTransform="uppercase">
            {billing === 'monthly' ? 'Monthly' : 'Yearly'}
          </Heading>
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Flex flexDir="row" justifyContent="center" alignItems="flex-end">
              <Heading>{priceLabel}</Heading>
              <Paragraph fontSize="xl" fontWeight="bold" mb="0">
                /MO
              </Paragraph>
            </Flex>
            <Paragraph fontSize="sm" textTransform="capitalize">
              {label}
            </Paragraph>
          </Flex>
          <Flex
            backgroundColor={billing === 'monthly' ? 'transparent' : 'primary'}
            py="1"
            px="4"
            justifyContent="center"
            alignItems="center"
            borderRadius="lg"
            minH={8}
            minW={130}
            display={label === 'monthly' ? 'none' : ['none', 'none', 'flex']}
          >
            {billing === 'yearly' && (
              <Text fontSize="sm" fontWeight="bold">
                Save 17%
              </Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PriceButton;
