import { Action } from 'types';
import { UIState } from 'types';
import { Auth } from 'redux/actionTypes';

const initialState: UIState = {
  isLoadingUser: true,
};

export const uiReducer = (state = initialState, action: Action): UIState => {
  switch (action.type) {
    case Auth.RETRIEVE_USER_REQUEST: {
      return { ...state, isLoadingUser: true };
    }
    case Auth.AUTH_COMPLETED:
    case Auth.RETRIEVE_USER_SUCCESS:
    case Auth.RETRIEVE_USER_FAILURE: {
      return { ...state, isLoadingUser: false };
    }
    default:
      return state;
  }
  
};
