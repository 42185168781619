import {
  Button,
  Divider,
  Flex,
  Heading,
  Link as ExternalLink,
  Paragraph,
  Spinner,
  Text,
} from '@workshop/ui';
import { useAuthMethods } from 'hooks/auth';
import useCheckout, { usePrices } from 'hooks/checkout';
import navRoutes from 'navigation/Routes';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { Link, useLocation } from 'react-router-dom';
import { GlobalState } from 'types';
import { getParamFromUrl } from 'utils';
import PriceButton from './PriceButton';

const logoLarge = require('../../../assets/logo_large.png');

const screenshot1 = require('../../../assets/app_screenshots/screenshot_1.png');
const screenshot2 = require('../../../assets/app_screenshots/screenshot_2.png');
const screenshot3 = require('../../../assets/app_screenshots/screenshot_3.png');
const screenshot4 = require('../../../assets/app_screenshots/screenshot_4.png');
const screenshot5 = require('../../../assets/app_screenshots/screenshot_5.png');
const screenshot6 = require('../../../assets/app_screenshots/screenshot_6.png');

const aspectRatio = 1398 / 645;

const defaultScreenshotStyle = {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '75vh',
  width: '100%',
} as const;

// Function that extracts the currency symbol from the price, e.g. $ from $10.00
const extractCurrencySymbol = (price: string) => price.replace(/[\d.]/g, '');

const SubscribeScreen: React.FC<{ history: any }> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState<'monthly' | 'annual'>('annual');

  const user = useSelector((state: GlobalState) => state.user);
  const { isLoadingUser } = useSelector((state: GlobalState) => state.ui);

  const { initCheckout, postCheckoutInProgress } = useCheckout({
    shouldLoadUser: false,
    history,
    eventId: 'web_app_signup',
    loadingState: { isLoading, setIsLoading },
  });

  const { prices } = usePrices({ loadingState: { isLoading, setIsLoading } });

  const { signOut } = useAuthMethods({});

  const location = useLocation();
  const couponCode = getParamFromUrl(location, 'coupon_code');

  const monthlyPrice = prices ? prices.find(p => p.price.billingCycle?.interval === 'month') : null;
  const annualPrice = prices ? prices.find(p => p.price.billingCycle?.interval === 'year') : null;
  const currencySymbol = extractCurrencySymbol(annualPrice?.formattedTotals.total || '');

  const loginUrl = navRoutes.public.login.path();

  useEffect(() => {
    if (isLoadingUser) return;
    if (user?.subscriptionStore) {
      history.push(navRoutes.public.manageSubscription.path());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser, user?.subscriptionStore]);

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Choose your plan</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoLarge}
        width="140px"
        height="30px"
        position="absolute"
        left={4}
        bottom={4}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom"
      />
      {postCheckoutInProgress && (
        <Flex
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1000}
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1000}
            bgColor="black"
            opacity={0.2}
          />
          <Spinner />
        </Flex>
      )}
      <Flex
        flex="0 0 40%"
        backgroundColor="primary"
        flexDirection="column"
        alignItems="center"
        height={['0', '0', '100vh']}
        pt={['5', '5', '20']}
        pb={['5', '5', '10']}
        px={['0', '0', '20']}
      >
        <Heading display={['flex', 'flex', 'none']} fontSize={['2xl', '2xl', '4xl']}>
          Select your billing plan
        </Heading>
        <Flex display={['none', 'none', 'flex']}>
          <Flex
            borderRadius="lg"
            overflow="hidden"
            width="100%"
            height="100%"
            borderWidth="4px"
            borderColor="#00000036"
            style={{
              boxShadow: '6px 10px 20px -6px black',
            }}
          >
            <Carousel
              autoPlay
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              showIndicators={false}
              renderArrowNext={() => null}
              renderArrowPrev={() => null}
              width={`${75 / aspectRatio}vh`}
              swipeable
            >
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot1})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot2})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot3})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot4})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot5})` }} />
              <div style={{ ...defaultScreenshotStyle, backgroundImage: `url(${screenshot6})` }} />
            </Carousel>
          </Flex>
        </Flex>
      </Flex>
      {isLoadingUser ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '0']}
          maxW={750}
          height="100%"
        >
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent={['flex-start', 'flex-start', 'center']}
          height="100vh"
          backgroundColor="white"
          flex={1}
          px={['10', '10', '20']}
          pt={['10', '10', '0']}
          maxW={750}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading display={['none', 'none', 'flex']} fontSize={['2xl', '2xl', '4xl']} mb="2">
            Select your billing plan
          </Heading>
          <Paragraph>Choose a plan for after your 1-month free trial</Paragraph>

          <Flex flexDir="column" flex={1} width="100%">
            <Flex flexDir="column" flex={1} width={['100%', '100%', '100%', '85%']} mt="8">
              {
                <Flex
                  flexDir={['column-reverse', 'row', 'row']}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <PriceButton
                    billing="monthly"
                    isLoading={isLoading}
                    isSelected={selectedPrice === 'monthly'}
                    label="Billed Monthly"
                    onClick={() => setSelectedPrice('monthly')}
                    priceLabel={monthlyPrice?.formattedTotals.total || ''}
                  />
                  <Flex mx={['0', '2', '2']} my={['2', '0', '0']} />
                  <PriceButton
                    priceLabel={`${currencySymbol}${
                      (parseInt(annualPrice?.totals.total || '0') / 100 / 12)
                        .toFixed(2)
                        .toString() || ''
                    }`}
                    billing="yearly"
                    isLoading={isLoading}
                    isSelected={selectedPrice === 'annual'}
                    label={`/ ${annualPrice?.formattedTotals.total || ''} Billed Yearly`}
                    onClick={() => setSelectedPrice('annual')}
                  />
                </Flex>
              }
              <Button
                variant="solid"
                colorScheme="blue"
                type="submit"
                textTransform="initial"
                mt={4}
                fontSize="md"
                isLoading={isLoading}
                onClick={() => {
                  const priceId =
                    monthlyPrice?.price.id && annualPrice?.price.id
                      ? selectedPrice === 'monthly'
                        ? monthlyPrice.price.id
                        : annualPrice.price.id
                      : null;

                  if (user?.email && priceId) {
                    initCheckout(priceId);
                    return;
                  }

                  history.push(
                    `${navRoutes.public.signup.path()}?price_id=${priceId}${
                      couponCode && couponCode !== 'null' ? `&coupon_code=${couponCode}` : ''
                    }`,
                  );
                }}
                label="START MY FREE TRIAL"
                w="100%"
              />
            </Flex>
            <Flex flexDirection="column" flex={1}>
              <Flex mt="2" py={4} width="100%" flexDir="column">
                <Text flex={1} mb="6" fontSize="sm">
                  By continuing, you are indicating that you accept our{' '}
                  <ExternalLink href="https://sortedfood.com/website-terms/" target="_blank">
                    <Text as="span" fontWeight="bold" color="secondary">
                      Terms of Service
                    </Text>
                  </ExternalLink>{' '}
                  and{' '}
                  <ExternalLink href="https://sortedfood.com/privacy-policy/" target="_blank">
                    <Text as="span" fontWeight="bold" color="secondary">
                      Privacy Policy.
                    </Text>
                  </ExternalLink>
                </Text>
                <Divider />
                {user?.email ? (
                  <>
                    <Text flex={1} fontSize="sm" mt="6" mb="2">
                      You are currently signed in as: {user.email}
                    </Text>
                    <Text
                      flex={1}
                      fontSize="sm"
                      onClick={signOut}
                      as="span"
                      fontWeight="bold"
                      color="secondary"
                      cursor="pointer"
                    >
                      Sign out.
                    </Text>
                  </>
                ) : (
                  <>
                    <Text flex={1} fontSize="md" fontWeight="bold" mt="6" mb="2">
                      Already have an account?
                    </Text>
                    <Text flex={1} fontSize="sm">
                      <Link to={loginUrl}>
                        <Text as="span" fontWeight="bold" color="secondary">
                          Sign in
                        </Text>{' '}
                        to manage your subscription.
                      </Link>
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SubscribeScreen;
