import { useCallback } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import { decamelizeKeys } from 'humps';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { API, auth } from 'utils';
import authTokenManager from 'utils/authTokenManager';

type HTTPArgs = AxiosRequestConfig & {
  dispatch: Dispatch;
  url: string;
  data?: any;
  signal?: AbortSignal;
};

const methodMap = {
  GET: axios.get,
  POST: axios.post,
  DELETE: axios.delete,
  PATCH: axios.patch,
  PUT: axios.put,
} as const;

/**
 * Wrapper around axios that automatically adds the Authorization header.
 * This function also checks if the user's Firebase ID Token is close to expiring,
 * and if so, generates a new one.
 * @param args - The arguments to pass to axios
 * @returns The response from the API
 */
async function axiosWrapper<T>(args: HTTPArgs) {
  const { dispatch, data, url, method = 'GET', ...rest } = args;

  let token = authTokenManager.getToken();

  // On every API call, check whether the user's Firebase ID Token is close to expiring.
  // If we're within 5 minutes of the token expiring, then we forcefully generate
  // a new one and update our record of when the new token expires
  const user = auth.currentUser;
  if (user) {
    token = await authTokenManager.refreshAuthToken(user);
  }

  const axiosConfig = {
    ...rest,
    baseURL: API.baseURL,
    headers: {
      'Content-type': 'application/json',
      Accept: `application/json; version=${API.version}`,
      // if authToken is null, then we don't want to send the Authorization header
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
  const axiosMethod = methodMap[method as keyof typeof methodMap];

  return method === 'POST' || method === 'PATCH'
    ? axiosMethod<T>(url, decamelizeKeys(data), axiosConfig)
    : axiosMethod<T>(url, axiosConfig);
}

export type CallAPIArgs = Omit<AxiosRequestConfig, 'url' | 'method'> & {
  url: string;
  method: keyof typeof methodMap;
  data?: Record<any, any>;
  signal?: AbortSignal;
};

/**
 * Custom hook to call the API with the correct headers.
 * @returns callAPI function
 */
const useCallAPI = (log = false) => {
  const dispatch = useDispatch();

  const callAPI = useCallback(
    <T>(args: CallAPIArgs) => {
      return axiosWrapper<T>({ dispatch, ...args });
    },

    [dispatch],
  );

  return { callAPI };
};

export default useCallAPI;
