import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ChakraProvider, Text, Theme } from '@workshop/ui';
import AppRouter from 'navigation/AppRouter';
import history from 'navigation/History';
import { Provider } from 'react-redux';
import store from 'redux/store';

import { queryClient } from './react-query/utils/queryClient';
if (process.env.NODE_ENV === 'production') {
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  const tracesSampleRate: number =
    process.env.REACT_APP_SENTRY_SAMPLE_RATE &&
    typeof process.env.REACT_APP_SENTRY_SAMPLE_RATE === 'number'
      ? process.env.REACT_APP_SENTRY_SAMPLE_RATE
      : 1.0;

  const routingInstrumentation = Sentry.reactRouterV5Instrumentation(history);
  const browserTracing = new Integrations.BrowserTracing({
    // Can also use reactRouterV4Instrumentation
    routingInstrumentation,
  });
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [browserTracing],
    tracesSampleRate,
    normalizeDepth: 5,
  });
}

// export const AMPLITUDE_API_KEY: string = process.env.REACT_APP_AMPLITUDE_API_KEY
//   ? (process.env.REACT_APP_AMPLITUDE_API_KEY as string)
//   : '';

// initAmplitude(AMPLITUDE_API_KEY, undefined, {
//   defaultTracking: {
//     pageViews: true,
//     sessions: true,
//     formInteractions: true,
//   },
// });

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={Theme}>
        <Sentry.ErrorBoundary fallback={<Text>An error has occurred</Text>}>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <AppRouter history={history} />
          </PersistQueryClientProvider>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
