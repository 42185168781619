import { History } from 'history';
import { useAuthenticateUser } from 'hooks/auth';
import React from 'react';
import { Redirect, Route as RouterRoute, Router, Switch } from 'react-router-dom';
import { useIsAuthenticated } from 'redux/selectors';
import navRoutes from './Routes';

interface RoutesRendererProps {
  isAuthenticated: boolean;
}

const Route = RouterRoute;

const RoutesRenderer: React.FC<RoutesRendererProps> = ({ isAuthenticated, ...rest }) => {
  return (
    <Switch>
      {Object.keys({ ...navRoutes.public }).map((k, idx) => {
        const route = navRoutes.public[k];
        const { path, component } = route;
        return <Route key={`route-${path()}_${idx}`} path={path()} component={component} />;
      })}
      <Redirect to={`${navRoutes.public.subscribe.path()}`} />
    </Switch>
  );
};

const AppRouter: React.FC<{ history: History<unknown> }> = ({ history }) => {
  const isAuthenticated = useIsAuthenticated();
  useAuthenticateUser();

  return (
    <Router history={history}>
      <RoutesRenderer isAuthenticated={isAuthenticated} />
    </Router>
  );
};

export default AppRouter;
