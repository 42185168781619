import { useEffect } from 'react';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import useCallAPI, { CallAPIArgs } from './http';
import { useToast } from '@chakra-ui/toast';

export type MessageArgs = {
  errorMessage?: string;
  errorMessagesMap?: { default: string } & Record<string, string>;
  successMessage?: string;
};

type UMWrapperArgs<APIResponse, MutationFNArgs> = MessageArgs & {
  getApiArgs: (args: MutationFNArgs) => CallAPIArgs;
} & UseMutationOptions<AxiosResponse<APIResponse, any>, Error, MutationFNArgs, unknown>;

export function useMutationWrapper<APIResponse, MutationFNArgs>(
  args: UMWrapperArgs<APIResponse, MutationFNArgs>,
) {
  const { callAPI } = useCallAPI();

  const errorToast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  const successToast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: { backgroundColor: 'statusSuccess' },
  });

  const { errorMessage, errorMessagesMap, successMessage, getApiArgs, ...rest } = args;
  const mutation = useMutation({
    ...rest,
    mutationFn: fnArgs => callAPI<APIResponse>(getApiArgs(fnArgs)),
  });

  useEffect(() => {
    if (errorMessagesMap && mutation.isError) {
      const defaultErrorMessage = errorMessagesMap.default;
      const { error } = mutation;
      const foundError = Object.keys(errorMessagesMap).find(key => error.message.includes(key));
      errorToast({
        title: 'Error',
        description: foundError ? errorMessagesMap[foundError] : defaultErrorMessage,
        status: 'error',
        duration: 2000,
        isClosable: false,
      });

      return;
    }

    if (errorMessage && mutation.isError) {
      errorToast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 2000,
        isClosable: false,
      });

      return;
    }

    if (mutation.isSuccess && successMessage) {
      successToast({
        title: 'Error',
        description: successMessage,
        status: 'error',
        duration: 2000,
        isClosable: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isError, mutation.isSuccess, errorMessage, successMessage]);

  return mutation;
}

export default useMutationWrapper;
