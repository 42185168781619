import useMutationWrapper, { MessageArgs } from 'react-query/utils/useMutationWrapper';
import { API } from 'utils';

export const useMarkDiscountCodeRedeemed = ({ ...args }: {} & MessageArgs) => {
  const { mutate, ...mutation } = useMutationWrapper<null, string>({
    ...args,
    onSuccess: () => {},
    getApiArgs: (discountCode: string) => ({
      url: API.urls.markDiscountCodeRedeemed(discountCode),
      method: 'POST',
    }),
  });

  return { markDiscountCodeRedeemed: mutate, ...mutation };
};

/**
 * This API is using only for migrating existing Stripe users to Paddle
 * The API creates a Paddle transaction, then returns a price_id and transaction_id
 * which can both be passed to PaddleJS for checkout.
 */
export const useCreateDraftTransaction = ({ ...args }: {} & MessageArgs) => {
  // UseMutationResult<AxiosResponse<APIResponse, any>, Error, MutationFNArgs, unknown>
  // UseMutationResult<AxiosResponse<string, any>, Error, void, unknown>
  const { mutateAsync, ...mutation } = useMutationWrapper<{ transaction_id: string }, void>({
    ...args,
    onSuccess: () => {},
    getApiArgs: () => ({
      url: API.urls.createDraftTransaction(),
      method: 'POST',
    }),
  });

  return { createDraftTransaction: mutateAsync, ...mutation };
};
