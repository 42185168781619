import { UserDetailState } from 'types/user/state';
import { Action } from 'types';
import { Auth } from 'redux/actionTypes';

export const initialUserState: UserDetailState = {
  id: 0,
  uid: '',
  username: '',
  name: '',
  firstName: '',
  email: '',
  isStaff: false,
  hasCompletedSignup: false,
  reasonsForJoining: [],
  cookingSkillLevel: 0,
  isEmailSubscriber: false,
  hasFreemiumAccess: false,
  settings: { weekStartDay: 1, onboardingCompleted: false, nutritionalTags: [] },
  canAccessMenu: { value: false, count: 0, message: '' },
  canAccessCooking: { value: false, count: 0, message: '' },
  hasHadSubscription: false,
  isEligibleForFreeTrial: true,
};

const userDetailReducer = (
  state: UserDetailState = initialUserState,
  action: Action,
): UserDetailState => {
  switch (action.type) {
    case Auth.RETRIEVE_USER_SUCCESS:
      return { ...state, ...action.payload };

    case Auth.SIGN_OUT:
      return { ...initialUserState };

    default:
      return state;
  }
};

export { userDetailReducer };
