import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { auth } from 'utils';

import { Flex, Text, Heading } from '@workshop/ui';

import { ForgotPasswordForm } from 'screens/ForgotPassword/ForgotPasswordForm';

const logoLarge = require('../../../assets/logo_large.png');
const gherkinImg = require('../../../assets/crazy_gherkin@3x.png');

const ForgotPasswordScreen: React.FC = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      // @ts-ignore
      if (err?.code === 'auth/user-not-found') {
        // TODO : add error handling
      }
    }

    setShowSuccessMessage(true);
  };

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Password reset</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoLarge}
        width="140px"
        height="30px"
        position="absolute"
        left={4}
        bottom={4}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom"
      />
      <Flex
        alignItems="center"
        backgroundColor="primary"
        flex="0 0 40%"
        flexDirection="column"
        height={['0', '0', '100vh']}
        justifyContent="flex-start"
        pb={['5', '5', '0']}
        pt={['5', '5', '40']}
        px={['0', '0', '0']}
      >
        <Heading display={['flex', 'flex', 'none']} fontSize={['2xl', '2xl', '4xl']}>
          Reset your password
        </Heading>
        <Flex
          display={['none', 'none', 'block']}
          width="100%"
          height="15vw"
          backgroundImage={gherkinImg}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        minHeight="100vh"
        backgroundColor="white"
        flex={1}
        px={['10', '10', '20']}
        pt={['10', '10', '0']}
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        <Heading
          display={['none', 'none', 'flex']}
          fontSize={['2xl', '2xl', '4xl']}
          mb="8"
          maxW={500}
        >
          Reset your password
        </Heading>
        {showSuccessMessage ? (
          <Flex flex={1} width="100%" maxW={500}>
            <Flex flexDirection="column" flex={1}>
              <Text textAlign="left" fontWeight="normal" fontSize={['lg', 'lg', 'xl']} mb="8">
                An email containing a link to reset your password is on its way.
              </Text>
            </Flex>
          </Flex>
        ) : (
          <ForgotPasswordForm onSubmit={onSubmit} />
        )}
      </Flex>
    </Flex>
  );
};

export default ForgotPasswordScreen;
