const recipeStatus = {
  UNDETERMINED: 'UNDETERMINED',
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
} as const;

const packStatus = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
} as const;

// ------------------ GENERAL ------------------ //
export enum ContentType {
  recipe = 'recipe',
  pack = 'recipepack',
}

// ------------------ INGREDIENTS ------------------ //
export type NutritionalTag = 'NF' | 'GF' | 'EF' | 'DF' | 'VE' | 'V' | 'M' | 'F';

export type Unit = {
  id: number;
  name: string;
  nameAbbrev: string;
  pluralAbbrev: string;
  system: 'si' | 'imperial';
  type: 'other' | 'mass' | 'volume';
};

type DeprecatedCategoryName = 'staple' | 'weekly';
type CategoryName = 'store cupboard' | 'fresh';

export type Category = {
  id: number;
  name: DeprecatedCategoryName | CategoryName;
  shoppingListDisplay: string;
};

export enum IngredientTypes {
  alcohol = 'Alcohol',
  bread = 'Bread',
  carbsGrainsSugars = 'Staple Carbs / Grains / Sugars',
  dairyEggs = 'Dairy / Eggs',
  equipment = 'Equipment',
  frozen = 'Frozen',
  fruit = 'Fruit / Veg / Herbs',
  meatFishShellfish = 'Meat / Fish / Shellfish',
  nonFood = 'Non-food',
  nutsSeedsDriedFruits = 'Nuts / Seeds / Dried Fruit',
  seasonings = 'Seasonings',
  seasoningsSpices = 'Seasonings / Spices',
  staple = 'Staple Carbs / Grains',
  tinsBottlesJars = 'Tins / Bottles / Jars',
  tinsJarsBottles = 'Tins / Jars / Bottles',
}

type IngredientType = {
  id: number;
  name: IngredientTypes;
  order: number;
};

export type Ingredient = {
  // 2022-08-27
  category: Category;
  cost: number;
  id: number;
  image: {
    fullSize?: string;
    mobile?: string;
    thumbnail?: string;
  };
  name: string;
  nutritionalTags: Array<NutritionalTag>;
  quantity: number;
  quantityImperial: number;
  type: IngredientType | null;
  unit: Unit;
  unitImperial: Unit;
  /**
   * A value ranging from 1 (short shelf life < 4 days) - 5 (long shelf life >= 1 year)
   */
  shelfLife: number;
  /**
   * Visibility flag - only show ingredients where this is 'visibile'
   */
  visibility: 'visible' | 'hidden';
  recipeCount: number;
};

export type IngredientGroupType = 'ingredient' | 'ingredientgroup' | 'ingredientparentgroup';

export interface IngredientGroup {
  name: string;
  image?: { fullSize?: string; thumbnail?: string; mobile?: string };
  type?: IngredientGroupType;
}

export type SearchIngredientResponse = {
  ingredients: IngredientGroup[];
  ingredientGroups: IngredientGroup[];
  ingredientParentGroups: IngredientGroup[];
};

// ------------------ MEMBERSHIP ------------------ //

export enum MembershipSourceOpts {
  wordpress = 'wordpress',
  google = 'google',
  apple = 'apple',
}

export type MembershipSource = keyof typeof MembershipSourceOpts;

export type LogPurchasePayload = {
  transactionId: string;
  fetchToken: string;
};

export type Membership = {
  dateCreated: string;
  valid: boolean;
  status: 'active' | 'delayed' | 'complimentary' | 'pending' | 'paused' | 'expired' | 'cancelled';
  source: MembershipSource;
  startDate: string;
  endDate: string | null | undefined;
  receipt?: string;
  productId: string;
  transactionId?: string;
  app?: string;
  price: number;
  currency: string;
};

export type AppStoreConnectStatus = 'pending' | 'in_progress' | 'complete' | 'failed';

// ------------------ RECIPE ------------------ //
export type UserList =
  | 'recipe_favourites'
  | 'pack_favourites'
  | 'pack_want_to_cook'
  | 'recipe_want_to_cook';

export type UserListItem = {
  id: number;
  created: string;
  modified: string;
  uid: string;
  userUid: string;
  targetObjectUid: string;
  listType: UserList;
  targetContentType: ContentType;
};

export type UserListItemResponse = Omit<UserListItem, 'targetContentType'> &
  // Technically `item` can be non-existent if there is a data error - i.e. the uid
  // of the object is invalid
  (| { targetContentType: ContentType.pack; item?: PackSummary }
    | { targetContentType: ContentType.recipe; item?: RecipeSummary }
  );

export enum DietaryTag {
  meat = 'M',
  fish = 'F',
  meatAndFish = 'M&F',
  veg = 'V',
  vegan = 'VE',
}

export type AltImages = {
  fullSize: string;
  small: string;
  thumbnail: string;
  medium: string;
};

export type Twist = {
  id: number;
  objectId: number;
  title: string;
  comment: string;
  name: string | null;
  city: string;
  country: string;
  approved: boolean;
  contentType: ContentType;
  hasLiked: boolean;
  likeCount: number;
  category: 'community' | 'chef';
};

// 2022-08-27
export type CookingTime = {
  id: number;
  numPeople: number;
  duration: number;
  recipe: number;
};

// 2022-08-27
export type StepTaskTimer = {
  duration: number;
  endAudio: string;
  id: number;
  index: string;
  instruction: string;
  recipeCode: string;
  title: string;
};

// 2022-08-27
export type StepTask = {
  audio: string;
  id: number;
  isTimerEndTask: boolean;
  method: string;
  step: number;
  taskNumber: number;
  timer: StepTaskTimer | null;
  numPeople: number;
};

// 2022-08-27
export type MethodStep = {
  altHeaderPhotos: AltImages;
  headerAudio: string;
  headerPhoto: string;
  id: number;
  method: number;
  numPeople: number;
  stepNumber: number;
  tasks: StepTask[];
  title: string;
};

// 2022-08-27
export type Method = {
  id: number;
  recipe: number;
  steps: MethodStep[];
};

// 2022-08-27
export type IngredientQuantity = {
  id: number;
  numPeople: number;
  prepInstructions: string | null;
  quantity: number;
  recipeIngredient: number;
  unit: Unit;
};

// 2022-08-27
export type RecipeIngredient = {
  id: number;
  index: number;
  ingredient: number | null;
  group: string | null;
  quantities: Array<IngredientQuantity>;
  recipe: number;
};

// 2022-08-27
export type RecipeEquipment = {
  equipment: number;
  id: number;
  portion: 'any' | 'primary' | 'secondary';
  quantity: number;
  recipe: number;
};

export type Rating = {
  id: number;
  count: number;
  total: number;
  average: string;
  targetContentType: ContentType;
  targetObjectUid: string;
} | null;

export type RecipeType = 'default' | 'youtube' | 'standalone';

// 2022-08-27
export interface RecipeSummaryResponse {
  attributeTags: string[];
  budget: boolean;
  code: string;
  cookingTime: CookingTime[];
  cuisineTags: string[];
  dietaryTag: DietaryTag;
  id: number;
  uid: string;
  image: string | null;
  ingredientCount: number;
  nutritionalTags: NutritionalTag[];
  pack: number | null;
  packTitle: string | null;
  recipeEquipment: number[];
  recipeIngredients: number[];
  resizedImages: AltImages;
  resizedFeaturedImages: AltImages;
  recipeType: RecipeType;
  relatedRecipes: { id: number; uid: string }[];
  portionSize: string | null;
  seasonalTags: string[];
  story: string;
  title: string;
  userLists: UserList[];
  rating: Rating;
}

export interface RecipeSummary extends RecipeSummaryResponse {}

export interface RecipeResponse
  extends Omit<RecipeSummaryResponse, 'recipeEquipment' | 'recipeIngredients'> {
  ingredients: Ingredient[];
  method: Method;
  recipeEquipment: RecipeEquipment[];
  recipeIngredients: RecipeIngredient[];
  recommendedRecipes: RecipeSummary[];
}

export type Recipe = Omit<
  RecipeResponse,
  'method' | 'ingredients' | 'recipeEquipment' | 'recipeIngredients' | 'recommendedRecipes'
> & {
  method: number;
  ingredients: number[];
  recipeEquipment: number[];
  recipeIngredients: number[];
  recommendedRecipes: number[];
};

// Recipe Schedules
export type RecipeSchedule = {
  id: number;
  uid: string;
  created: string;
  modified?: string;
  recipe: number;
  scheduledDate?: string;
  cookedDate?: string;
  weekNumber: number;
  year: number;
};

export type RecipeScheduleResponse = Omit<RecipeSchedule, 'recipe'> & { recipe: RecipeSummary };

export type RecipeScheduleListResponse = {
  scheduledRecipes: RecipeScheduleResponse[];
  relatedRecipes: RecipeSummary[];
};

// 2022-08-27
export interface PackSummaryResponse {
  attributeTags: string[];
  budget: boolean;
  code: string;
  cookingTimeRange: number[];
  dietaryTag: DietaryTag;
  firstRecipeImages: AltImages;
  id: number;
  uid: string;
  ingredientCount: number;
  name: string;
  nutritionalTags: NutritionalTag[];
  photo: string | null;
  portionSize: string;
  primaryPortionOnly: boolean;
  publishedAt: string | null;
  recipes: number[] | RecipeSummary[];
  resizedPhotos: AltImages;
  seasonalTags: string[];
  story: string;
  themes: string[];
  userLists: UserList[];
  rating: Rating;
}

// 2022-08-27
export interface PackSummary extends Omit<PackSummaryResponse, 'recipes'> {
  recipes: number[];
}

// 2022-8-27
export interface PackResponse extends Omit<PackSummaryResponse, 'recipes'> {
  ingredients: Ingredient[];
  recipeEquipment: { [key: number]: RecipeEquipment[] };
  recipeIngredients: { [key: number]: RecipeIngredient[] };
  recipes: RecipeSummary[];
  twists: Twist[];
}

export type Pack = Omit<
  PackResponse,
  'recipes' | 'recipeEquipment' | 'recipeIngredient' | 'twists'
> & {
  recipes: string[];
  recipeEquipment: { [key: number]: number[] };
  recipeIngredients: { [key: number]: number[] };
  twists: number[];
};

// 2022-8-27
export type MenuResponse = {
  description: string;
  id: number;
  image:
    | {
        fullSize: string;
        mobile: string;
        thumbnail: string;
        tiny: string;
      }
    | null
    | undefined;
  label: string;
  packs: PackSummary[];
  published: boolean;
  schedule: string;
  scheduleType: 'weekly' | 'monthly' | 'featured';
  subtitle: string;
  title: string;
};

export type Menu = Omit<MenuResponse, 'packs'> & { packs: number[] };

export enum PackCategoryOrdering {
  newest = 'newest',
  ingredientCount = 'ingredient_count',
  cookingTime = 'cooking_time',
}

// 2022-8-27
export type PackCategoryResponse = {
  id: number;
  index: number;
  active: boolean;
  name: string;
  icon: string;
  images: AltImages;
  colour: string;
  categoryType: 'global_category' | 'user_category';
  displayType: 'explore' | 'search';
  packs: {
    results: PackSummaryResponse[];
    count: number;
    next: string | null;
    previous: string | null;
  };
};

export type PackCategory = Omit<PackCategoryResponse, 'packs'> & {
  packs: {
    results: number[];
    count: number;
    next: string | null;
    previous: string | null;
  };
};

export enum ContentCategoryOrdering {
  newest = 'newest',
  ingredientCount = 'ingredient_count',
  cookingTime = 'cooking_time',
}

export type ContentCategoryType = 'curated' | 'calculated';

export enum ContentCategoryGrouping {
  recipes = 'recipes',
  packs = 'packs',
  sortedfood = 'sortedfood',
}

export type ContentCategoryDisplayLocation = 'explore' | 'search' | 'menu';

export type ContentCategoryResponse = {
  id: number;
  uid: string;
  name: string;
  images: AltImages;
  categoryType: ContentCategoryType;
  active: boolean;
  ordering: number;
  displayLocation: ContentCategoryDisplayLocation[];
} & (
  | {
      grouping: ContentCategoryGrouping.recipes | ContentCategoryGrouping.sortedfood;
      items: {
        data: RecipeSummary[];
        count: number;
        next: string | null;
        previous: string | null;
      };
    }
  | {
      grouping: ContentCategoryGrouping.packs;
      items: {
        data: PackSummary[];
        count: number;
        next: string | null;
        previous: string | null;
      };
    }
);

export type ContentCategory = Omit<ContentCategoryResponse, 'items'> & {
  items: {
    data: number[];
    count: number;
    next: string | null;
    previous: string | null;
  };
};

// ------------------ SETTINGS ------------------ //

export type MeasurementSystem = 'si' | 'imperial';

// ------------------ USER ------------------ //

export type DayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type WeekStartDay = { label: string; value: DayValue };

export type UserDietaryPreference = NutritionalTag | 'none' | 'other';

/**
 * JSON configurable user settings. Settings can be quite transient so are assumed
 * to always possibly be undefined
 */
export type UserSettings = {
  nutritionalTags?: UserDietaryPreference | UserDietaryPreference[];
  ingredientDislikes?: string[];
  onboardingNumPeople?: number;
  dietaryTastes?: string[];
  weekStartDay?: DayValue;
  measurementSystem?: MeasurementSystem;
  onboardingCompleted?: boolean;
  countryCode?: string;
  countryName?: string;
  timeZone?: string;
  preferredCurrencies?: string[];
  /**
   * Last visited new feature id
   */
  lastVisitedNewFeatureId?: number;
  dislikedIngredientNames?: string[];
  appToursDismissed?: string[];
};

export type UserIngredientDislikes = {
  type: 'ingredient' | 'ingredientgroup';
  name: string;
};

export type UserDetail = {
  id: number;
  uid: string;
  name: string;
  firstName: string;
  username: string;
  isStaff: boolean;
  email: string;
  hasCompletedSignup: boolean;
  isEmailSubscriber: boolean;
  // Only available when posting data
  cookingSkillLevel?: number;
  reasonsForJoining?: number[];
  /**
   * If the user has a membership, this will always be `true`. However, for
   * freemium users, this will be `true` only if they haven't used up their
   * freemium allowance:
   * - 7 recipes can be added to the menu within a 2-week window
   */
  canAccessMenu: { value: boolean; count: number; message: string };
  /**
   * If the user has a membership, this will always be `true`. However, for
   * freemium users, this will be `true` only if they haven't used up their
   * freemium allowance:
   * - can cook up to 3 recipes
   */
  canAccessCooking: { value: boolean; count: number; message: string };
  /**
   * Flag telling us whether or not the user has ever had a subscription with us
   */
  hasHadSubscription: boolean;
  /**
   * JSON object containing user settings as specified in the UserSettings
   * type
   */
  settings: UserSettings;
  /**
   * An array of ingredient & ingredientGroup - migrated from the user settings
   */
  ingredientDislikes?: IngredientGroup[];
  /**
   * DEPRECATED:
   * Points to `canAccessMenu`
   */
  hasFreemiumAccess: boolean;
  subscriptionStore?: 'app_store' | 'google_play' | 'stripe' | 'paddle' | 'none' | null | undefined;
  isEligibleForFreeTrial: boolean;
};

// 2022-8-27
export interface UserRecipeResponse {
  id: number;
  recipe: number;
  // @ts-ignore
  status: ValueOf<typeof recipeStatus>;
  userPack: number | null;
  rating: number | null;
  feedback: string | null;
}

export interface UserRecipe extends UserRecipeResponse {}

// 2022-8-27
export interface UserPackResponse {
  datetimeSelected: string;
  id: number;
  numPeople: number;
  pack: number;
  source: string;
  // @ts-ignore
  status: ValueOf<typeof packStatus>;
  userRecipes: UserRecipe[];
  // DEPRECATED
  active: boolean;
  completed: boolean;
  datetimeLastUsed: string;
  rating: number | null;
  feedback: string | null;
  isFreemiumPack: boolean;
}

export interface UserPack extends Omit<UserPackResponse, 'userRecipes'> {
  userRecipes: number[];
}

// ------------------ CONFIG ------------------ //

export type PaywallConfig = {
  title: string;
  bulletPoints: string[];
  description: string;
  buttonLabel: string;
  billingDisclaimer: string;
  products: {
    id: string;
    button: {
      label: string;
      period: string;
    };
    subscriptionType: 'monthly' | 'annual';
  }[];
};

export interface NewFeaturePage {
  title: string;
  image?: string;
  cta: string | null;
  link: string | null;
  template: 'title' | 'detail';
  index: number;
}

export interface NewFeature {
  id: number;
  name: string;
  active: boolean;
  dismissible: boolean;
  minTargetAppVersion: string;
  maxTargetAppVersion: string;
  pages: NewFeaturePage[];
}
